import './bootstrap';
import '../scss/app.scss';

import { createApp, h } from 'vue';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import Mixpanel from '@/Plugins/Mixpanel';
import HoneybadgerVue from '@honeybadger-io/vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { createI18n } from 'vue-i18n';
import FloatingVue from 'floating-vue';
import { MotionPlugin } from '@vueuse/motion';
import VueGtag from 'vue-gtag';

import english from '../lang/en.json';

const honeybadgerConfig = {
  apiKey: import.meta.env.MIX_HONEYBADGER_API_KEY,
  environment: import.meta.env.MIX_APP_ENV,
};

const googleAnalyticsConfig = {
  config: { id: import.meta.env.MIX_GA_TRACKING_ID },
};

const floatingVueOptions = {
  themes: {
    'sparrow-tooltip': {
      // Default tooltip placement relative to target element
      placement: 'auto',
      // Default events that trigger the tooltip
      triggers: ['hover', 'focus', 'touch', 'click'],
      // Default events that trigger the popper
      popperTriggers: ['hover', 'focus', 'touch', 'click'],
      // Close tooltip on click on tooltip target
      hideTriggers: (events) => [...events, 'click'],
      // Delay (ms)
      delay: {
        show: 100,
        // Delay the tooltip hide to prevent it from disappearing
        // when the user moves the mouse from the trigger to the tooltip
        hide: 200,
      },
      // Update popper on content resize
      handleResize: true,
      instantMove: true,
      // Enable HTML content in directive
      html: true,
      // Displayed when tooltip content is loading
      loadingContent: '...',
    },
    'sparrow-tooltip-bottom': {
      $extend: 'sparrow-tooltip',
      placement: 'bottom',
    },
    'sparrow-tooltip-top': {
      $extend: 'sparrow-tooltip',
      placement: 'top',
    },
  },
};

const messages = {
  en: english,
};

const i18n = createI18n({
  local: 'en',
  messages,
});

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Sparrow';

/**
 * Converts a string to a URL-friendly slug.
 *
 * @param str {string}
 *
 * @returns {string}
 */
const slugify = (str) => str
  .toString()
  .toLowerCase()
  .trim()
  .replace(/[^\w\s-]/g, '')
  .replace(/[\s_-]+/g, '-')
  .replace(/^-+|-+$/g, '');

createInertiaApp({
  title: (title) => (title ? `${title} - ${appName}` : appName),

  resolve: async (name) => (await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))).default,

  setup({
    el,
    App,
    props,
    plugin,
  }) {
    const userId = props?.initialPage?.props?.user?.id;
    const inertiaApp = createApp({ render: () => h(App, props) })
      .use(plugin)
      .mixin({ methods: { route } })
      .mixin({ methods: { slugify } })
      .use(Mixpanel, { userId })
      .use(i18n)
      .use(FloatingVue, floatingVueOptions)
      .use(MotionPlugin)
      .use(VueGtag, googleAnalyticsConfig);

    if (!['local', 'testing'].includes(honeybadgerConfig.environment)) {
      inertiaApp.use(HoneybadgerVue, honeybadgerConfig);
    }

    return inertiaApp.mount(el);
  },

  progress: {
    delay: 100,
    color: '#2563eb',
    includeCSS: true,
    showSpinner: false,
  },
});
